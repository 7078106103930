"use strict";

import Vue from 'vue';
import axios from "axios";
import errorCode from "@/utils/errorCode";
import { Message } from "element-ui";
let $message = Message;

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: process.env.VUE_APP_BASE_API || "/prod-api"
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    console.log(config);
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    const code = response.data.code || 200;
    const msg = errorCode[code] || response.data.msg || errorCode["default"];
    if (code === 200) {
      return Promise.resolve(response.data);
    } else {
      console.log("code", code, msg);
      $message.error({
        message: msg,
        offset: 90
      });
      return Promise.reject(msg);
    }
  },
  function(error) {
    // Do something with response error
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    $message({
      type: 'error',
      message: message,
      duration: 3 * 1000
    })
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  console.log("options", options);
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
