<template>
  <header class="nav" id="nav">
    <div class="pc-nav autowidth flex flexct">
      <!-- <h1 class="nav-logo"></h1> -->
      <div class="flex flexct flex_1 flexend">
        <ul class="nav-menu">
          <li v-for="(item, index) in navList" :key="index" @click="onToggleNav(item)">
            <router-link :class="{ active: item.isActive }" :to="item.hrefLink">{{ item.label }}</router-link>
          </li>
        </ul>
        <div class="nav-login">
          <a @click="$router.push({ path: '/apply' })">免费试用</a>
        </div>
      </div>
    </div>
    <ul class="mobile-nav">
      <li v-for="(item, index) in navList" :key="index" @click="onToggleNav(item)">
        <router-link :class="{ active: item.isActive }" :to="item.hrefLink">{{ item.label }}</router-link>
      </li>
    </ul>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      navList: [
        {
          label: '首页',
          isActive: false,
          hrefLink: '/#index'
        },
        {
          label: '产品介绍',
          isActive: false,
          hrefLink: '/#product'
        },
        {
          label: '服务商城',
          isActive: false,
          hrefLink: '/price'
        },
        {
          label: '关于我们',
          isActive: false,
          hrefLink: '/#about'
        }
      ]
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(route) {
        this.$nextTick(() => {
          let navNode = document.querySelector('#nav')

          if (route.name === 'Home') {
            navNode.classList.remove('white-nav')
            document.addEventListener('scroll', this.onScroll)
          } else {
            navNode.classList.add('white-nav')
            document.removeEventListener('scroll', this.onScroll)
          }

          let activeNav = this.navList.find(item => {
            return item.label === route.meta.title
          })
          if (activeNav) {
            this.navList.forEach(item => {
              item.isActive = false
            })
            activeNav.isActive = true
          }
        })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('scroll', this.onScroll)
    })
  },
  methods: {
    onToggleNav(item) {
      if (item.label == '关于我们') {
        this.toBottom()
      }

      this.navList.forEach(nav => {
        nav.isActive = false
      })
      item.isActive = true
    },
    toBottom() {
      window.scrollTo(0, 50000)
    },
    onScroll() {
      let scrollTop = document.documentElement.scrollTop
      let clientHeight = document.documentElement.clientHeight
      let navHeight = document.querySelector('#nav').offsetHeight
      let appHeight = document.querySelector('#app').offsetHeight
      let indexHeight = document.querySelector('#index').offsetTop
      let productHeight = document.querySelector('#product').offsetTop

      if (scrollTop == appHeight - clientHeight) {
        this.onToggleNav(this.navList[3])
      } else if (scrollTop >= productHeight - navHeight) {
        this.onToggleNav(this.navList[1])
      } else if (scrollTop >= indexHeight - navHeight) {
        this.onToggleNav(this.navList[0])
      }
    }
  },
  destroyed() {
    document.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss" scoped>
.nav {
  // padding: 0 20px 0 26px;
  // height: 80px;
  // line-height: 80px;
  box-sizing: border-box;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2018;
}
.nav-abs {
  position: absolute;
}
.nav .nav-logo {
  float: left;
  line-height: 80px;
  text-align: center;
  height: 80px;
  width: 160px;
  background-image: url('../assets/images/whiteLogo.png');
  background-origin: content-box;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.nav .nav-logo img {
  height: 28px;
  width: 160px;
  vertical-align: middle;
}
.nav .nav-menu {
  float: right;
  min-width: 363px;
}
.nav .nav-menu li {
  float: left;
  padding: 0 30px;
  text-align: center;
}
.nav .nav-menu li a {
  font-size: 18px;
  line-height: 82px;
  font-weight: 500;
  font-family: 'Microsoft YaHei';
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: #fff;
}
.nav .nav-menu .active::after {
  content: '';
  width: 100%;
  display: inline-block;
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 22px;
  left: 50%;
  transform: translateX(-50%);
}
.nav .nav-login a {
  padding: 7px 15px;
  border-radius: 2px;
  margin-left: 6px;
  color: #fff;
  line-height: 32px;
  font-size: 16px;
  background-color: #007aff;
}
.nav.white-nav {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.1s linear;
}
.nav.white-nav .nav-menu li a {
  color: #1283ff;
  transition: color 0.1s linear;
}
.nav.white-nav .nav-menu .active::after {
  background-color: #015cd3;
}
.nav.white-nav .nav-login a {
  box-shadow: none;
  cursor: pointer;
  background-color: #1283ff;
}
.nav.white-nav .nav-logo {
  background-image: url('../assets/images/logo.png');
}
.nav .el-icon-menu {
  color: #fff;
}
.nav.white-nav .el-icon-menu {
  color: #1283ff;
}

.mobile-nav {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  display: none;
}
.mobile-nav li {
  float: left;
  color: #dddddd;
  font-size: 16px;
}
.mobile-nav li .active {
  color: #ffffff;
}
.white-nav .mobile-nav li {
  color: #9ec6f7;
}
.white-nav .mobile-nav li .active {
  color: #3895fb;
}
@media only screen and (max-width: 640px) {
  .nav {
    height: 40px;
    padding: 5px 5px;
  }
  .nav .nav-logo {
    width: 140px;
    height: 30px;
  }
  .mobile-nav {
    display: flex;
  }
}
</style>
