<template>
  <div id="app" class="is-component">
    <div v-if="showHeader">
      <Header v-if="!isApplet" />
    </div>
    <router-view />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  components: {
    Header
  },
  computed: {
    isApplet() {
      let source = JSON.parse(sessionStorage.getItem('source'))
      return source === 'applet'
    }
  },
  data(){
    return{
      showHeader:false
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(value) {
        if (value.source) {
          sessionStorage.setItem('source', JSON.stringify(value.source))
        }
      }
    },
    '$route': {
      immediate: true,
      deep: true,
      handler(value) {
        if(value.name){
          this.showHeader = !(value.name == 'Question')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
